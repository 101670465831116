import React, { Component } from "react";
import Whatsapp from "./Whatsapp";
import Facebook from "./Facebook";
import PhoneCall from "./PhoneCall";

export default class CompElement extends Component {
  render() {
    return (
      <div id="dashboard-contents">
        <div id="licenseinfo">
          <div className="header">
            <div className="title upper">
              AGENT ID: {this.props.id} - {this.props.type}
            </div>
            <div className="legend"></div>
          </div>
          <div className="body">
            <div className="code" style={{ width: "100%" }}>
              <table className="data" style={{ width: "100%" }}>
                <tbody>
                  <tr>
                    <th>MOBILE APP LINK</th>
                  </tr>
                  <tr>
                    <td className="header">
                      <div className="code" style={{ width: "100%" }}>
                        {this.props.phone ? (
                          <Whatsapp phone={this.props.phone} width="100" />
                        ) : (
                          ""
                        )}

                        {this.props.fb ? (
                          <Facebook fb={this.props.fb} width="100" />
                        ) : (
                          ""
                        )}
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <th>PHONE NUMBER</th>
                  </tr>
                  <tr>
                    <td className="header">
                      <div className="code" style={{ width: "100%" }}>
                        <h1>
                          <PhoneCall phone={this.props.phone} />
                        </h1>
                      </div>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
