import React, { Component } from 'react';
import { Link } from 'react-router-dom';

export default class ComplainLink extends Component {
  render() {
    let newType;

    switch (this.props.ctype) {
      case "site_admin":
        newType = "Site Admin";
        break;
      case "sub_admin":
      case "subadmin":
        newType = "sub_admin";
        break;
      case "customer_service":
        newType = "Customer Service";
        break;
      case "super_admin":
      case "superadmin":
        newType = "super";
        break;
      case "master_admin":
        newType = "master";
        break;
      case "affiliate":
        newType = "Affiliate";
        break;
      default:
        newType = "";
        break;
    }

    return (
      <>
        <Link to={`/complain/${newType.toLowerCase()}/${this.props.url}`} target="_blank" rel="noreferrer">
          অভিযোগ
        </Link>
      </>
    );
  }
}