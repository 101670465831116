import React, { Component } from 'react'

export default class Facebook extends Component {
  render() {
    return (
        <a href={this.props.fb} target="_blank">
            <img src="/images/fb.png" 
            width={this.props.width} 
            height={this.props.height} 
            alt="waImage"/>
        </a>
    )
  }
}
