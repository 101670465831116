import React, { Component } from "react";
import SpanImage from "../comp/SpanImage";
import { Link } from "react-router-dom";
import Navigation, { Nav1, Nav2, Nav3, NavFb } from "../comp/Navigation";
import Agent, { AgentBody } from "../comp/Agent";
import Modal from "../comp/Modal";
import Marquee from "../comp/Marquee";
import HomeElement from "../comp/HomeElement";
import VELKI_APP_NAME, {
  VELKI_API_URL,
  VELKI_API_KEY,
  VELKI_HOME_URL,
} from "../config/Constant";

const facebook_group = "https://mabia.in/m/fbgr1";
let datarules = [
  {
    title: "কিভাবে একাউন্ট খুলবেনঃ",
    head: "",
    desc: `vellki.live তে একাউন্ট করতে হলে আপনার এজেন্ট এর মাধ্যমে একাউন্ট খুলতে
    হবে। এজেন্ট এর মাধ্যমেই টাকা ডিপোজিট এবং উইথড্র করতে হবে। আপনি
    যে এজেন্ট এর কাছ থেকে একাউন্ট খুলবেন তার সাথেই সব সময় লেনদেন
    করতে হবে। ঠিক কোন এজেন্ট কে টাকা দিবেন এবং কিভাবে তার সাথে
    লেনদেন করবেন তার বুঝতে হলে আপনার নিম্বের তথ্য গুলো পড়া জরুরী।`,
  },
  {
    title: "এজেন্ট লিস্টঃ",
    head: "",
    desc: `একাউন্ট খুলতে নিম্বের অনলাইন এজেন্ট লিস্ট এ ক্লিক করুন। 
    এজেন্ট লিষ্ট এর এজেন্ট দের সাথে ইউজার দের শুধু মাত্র হোয়াটসাপ এর মাধ্যমে যোগাযোগ করতে হবে। 
    হোয়াটসাপ ছাড়া অন্য কোন মাধ্যমে যোগাযোগ করলে বা লেনদেন করলে তা গ্রহনযোগ্য হবে না। 
    হোয়াটসাপ এ যোগাযোগ করতে হলে এজেন্ট লিস্টে হোয়াটসাপ আইকন উপরে ক্লিক করুন অথবা 
    ফোন নাম্বার টি মোবাইলে সেভ করে তাকে হোয়াটসাপ এ মসেজ পাঠাতে পারবেন। 
    হোয়াটসাপ এপ টি আপনার মোবাইলে আগে থেকেই থাকতে হবে। না থাকলে গুগুল প্লে 
    থেকে ইন্সটল করে নিন। <br/>
    <a href="https://vellkiagentlist.live/master">অনলাইন মাষ্টার এজেন্ট লিস্টঃ</a> `,
  },
  {
    title: "এজেন্ট কয় প্রকারঃ",
    head: "",
    desc: `<a href="https://vellkiagentlist.live/superadmin">অনলাইন সুপার এজেন্ট লিস্টঃ</a>
     সুপার এজেন্ট রা, ইউজার একাউন্ট এবং মাষ্টার এজেন্ট একাউন্ট খুলে দিতে পারেন। <br/>
     কোন সুপার এজেন্ট এর নামে অভিযোগ থাকলে - সরাসরি এডমিন কে জানাতে হবে। 
     উপরে মেনু তে <a href="https://vellkiagentlist.live/admin">এডমিন লিষ্ট</a> দেয়া আছে।
     <a href="https://vellkiagentlist.live/master"> অনলাইন মাষ্টার এজেন্ট লিস্টঃ</a>
       অনলাইন মাষ্টার এজেন্ট রা, শুধু ইউজার একাউন্ট একাউন্ট খুলে দিতে পারেন। 
       কোন মাষ্টার এজেন্ট এর নামে অভিযোগ থাকলে - সরাসরি <a href="https://vellkiagentlist.live/superadmin">সুপার এজেন্ট </a>এর কাছে অভিযোগ 
       করতে হবে। <br/>
       <a href="https://facebook.com/">বিস্তারিত জানতে এই লিঙ্ক এ ক্লিক করুন। </a> <br/><br/>
       লোকাল <a href="https://vellkiagentlist.live/master">মাষ্টার এজেন্ট লিস্টঃ</a> 
       <br/>লোকাল মাষ্টার এজেন্ট রা,
        শুধু ইউজার একাউন্ট একাউন্ট খুলে দিতে পারেন। <br/>
        কিন্তু তাদের সাথে লেনদেন প্রতিটি ইউজার কে নিজ দায়িত্বে লেনদেন করতে হবে। 
        তাদের নামে কোন অভিযোগ কারো কাছে করা যাবে না।  <br/>
        লোকাল মাষ্টার এজেন্টঃ এই সব এজেন্ট সাধারনত – নিজের এলাকায় বা পরিচিত দের সাথে লেনদেন করে । 
        যারা আগে বাজি ধরিয়ে দিত, তাদের কেই মুলত লোকাল এজেন্ট দেয়া হয়েছে। 
        লোকাল এজেন্ট রা অনলাইনে আসে না এবং তারা তাদের পরিচয় গোপন রাখতে চায়। 
        লোকাল এজেন্ট দের সাথে অনলাইনে কোন ভাবেই লেনদেন করবেন না, 
        আর করে থাকলে তার দায়ভার পুরোটাই আপনার।`,
  },
  {
    title: "OFFICIAL WEBSITE",
    head: "",
    desc: `<a href="https://vellki.live">vellki.live</a>`,
  },
];

let admindata = [
  {
    id: "0",
    agent_type: "Site Admin",
    agent_name: "Agent Name",
    agent_phone: "000000000000",
    agent_upline: "1",
    agent_altphone: null,
    agent_email: "",
    agent_uodateon: "2022-09-17 01:10:29",
    agent_creted: "2022-09-17 01:10:29",
  },
];
console.log(datarules);
//"http://localhost/agent/api/?agent_type=site_admin";

export default class Home extends Component {
  constructor() {
    super();
    this.state = {
      agents: admindata,
      isloading: false,
      datarules: datarules,
    };
  }

  async componentDidMount() {
    console.log("cdm");
    console.log(this.state.datarules);
    let a = this.state.datarules;
    let url = `${VELKI_API_URL}/api/?agent_type=master_admin&type=master`;
    let res = await fetch(url);
    let parsedData = await res.json();
    if (parsedData.data) {
      this.setState({
        agents: parsedData.data,
        isloading: false,
        datarules: a,
      });
    }
    // console.log("Parsed");
    // console.log(parsedData.data);
  }

  render() {
    const { agents, isloading, datarules } = this.state;
    // console.log("Render Agents:");
    // console.log(typeof agents);
    // console.log(agents[0]);
    // console.log(isloading);

    return (
      <>
        <div>
          <main className="container-xl d-flex gap-3 flex-column">
            <section className="menubar-section d-flex flex-column gap-3">
              <Nav1 />
              <Nav2 />
              <Nav3 />
              <Marquee />
              <NavFb />
            </section>

            <section className="master-agent-section">
              <div className="d-flex justify-content-start align-items-center gap-2">
                <div className="bg-prime p-4">
                  <h6 className="text-center text-danger">
                    Quick master agent number
                  </h6>
                  <div
                    className="bg-sec p-3 p-md-2 border-radius"
                    style={{ overflowX: "auto" }}
                  >
                    <table className="w-100 text-white">
                      <tbody>
                        {agents.slice(0, 1).map((item) => (
                          <tr
                            key={item.id}
                            className="d-flex justify-content-between align-items-center gap-2 gap-md-3"
                          >
                            <td>
                              <div className="d-flex flex-column justify-content-start align-items-start">
                              <span className="text-white">
                                  {item.agent_name}
                                </span>
                              <small className="badge badge-sm bg-success">
                                  Master Agent
                                </small>
                              </div>
                            </td>
                            <td>
                              <div className="d-flex flex-column justify-content-center py-2 px-1 fw-medium align-items-center bg-white border-radius text-black">
                                <span>ID</span>
                                <span>{item.id}</span>
                              </div>
                            </td>
                            <td>
                              <div className="d-flex flex-column justify-content-start align-items-start">
                                <span style={{ fontSize: "14px" }}>
                                  {item.agent_phone}
                                </span>
                                <a
                                  href={`https://wa.me/${item.agent_phone}`}
                                  className=""
                                  target="_blank"
                                  rel="noreferrer"
                                >
                                  <small className="badge badge-sm bg-success">
                                    Whatsapp
                                  </small>
                                </a>
                              </div>
                            </td>
                            <td>
                              <div className="flex-column justify-content-start align-items-start gap-2 d-none d-md-flex">
                                <a
                                  href={`https://wa.me/${item.agent_phone}`}
                                  className="btn btn-sm btn-success w-100"
                                  target="_blank"
                                  rel="noreferrer"
                                >
                                  Message
                                </a>

                                <Link
                                  to={`/complain/super/${
                                    item.agent_upline
                                  }`}
                                  target="_blank"
                                  rel="noreferrer"
                                >
                                  <button
                                    style={{
                                      backgroundColor: "red",
                                      color: "#fff",
                                      fontSize: "16px",
                                    }}
                                    className="btn btn-sm py-0 w-100"
                                  >
                                    Report
                                  </button>
                                </Link>
                              </div>
                              <div className="flex-column justify-content-start align-items-start gap-2 d-flex d-md-none">
                                <small className="badge badge-sm bg-success w-100">
                                  Message
                                </small>
                                <small className="badge badge-sm bg-danger w-100">
                                  Report
                                </small>
                              </div>
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </section>
            <section className="agent-type">
              <div className="d-flex gap-3">
                <div className="bg-prime p-3">
                  <h6 className="text-center text-danger">এজেন্ট কয় প্রকারঃ</h6>
                  <div className="bg-sec p-2 border-radius d-flex flex-column flex-md-row gap-2 mt-3">
                    <div
                      className="border-radius p-3 text-center text-white d-flex justify-content-between flex-column gap-3"
                      style={{ backgroundColor: "#272A34" }}
                    >
                      <div className="grid-heading6">
                        <h6>অনলাইন সুপার এজেন্ট লিস্টঃ</h6>
                      </div>
                      <div>
                        <p>
                          সুপার এজেন্ট রা, ইউজার একাউন্ট এবং মাষ্টার এজেন্ট
                          একাউন্ট খুলে দিতে পারেন। কোন সুপার এজেন্ট এর নামে
                          অভিযোগ থাকলে সরাসরি এডমিন কে জানাতে হবে।
                        </p>
                      </div>
                      <div>
                        <a
                          href="#1"
                          className="btn btn-sm btn-danger text-white"
                        >
                          Report
                        </a>
                      </div>
                    </div>
                    <div
                      className="border-radius p-3 text-center text-white d-flex justify-content-between flex-column"
                      style={{ backgroundColor: "#272A34" }}
                    >
                      <div className="grid-heading6">
                        <h6>অনলাইন মাষ্টার এজেন্ট লিস্টঃ</h6>
                      </div>
                      <div>
                        <p>
                          অনলাইন মাষ্টার এজেন্ট রা, শুধু ইউজার একাউন্ট একাউন্ট
                          খুলে দিতে পারেন। কোন মাষ্টার এজেন্ট এর নামে অভিযোগ
                          থাকলে সরাসরি সুপার এজেন্ট এর কাছে অভিযোগ করতে হবে।
                        </p>
                      </div>
                      <div>
                        <a
                          href="#1"
                          className="btn btn-sm btn-danger text-white"
                        >
                          Report
                        </a>
                      </div>
                    </div>
                    <div
                      className="border-radius p-3 text-center text-white d-flex justify-content-between flex-column"
                      style={{ backgroundColor: "#272A34" }}
                    >
                      <div className="grid-heading6">
                        <h6>লোকাল মাষ্টার এজেন্ট লিস্টঃ</h6>
                      </div>
                      <div>
                        <p>
                          লোকাল মাষ্টার এজেন্ট রা, শুধু ইউজার একাউন্ট একাউন্ট
                          খুলে দিতে পারেন। কিন্তু তাদের সাথে লেনদেন প্রতিটি
                          ইউজার কে নিজ দায়িত্বে লেনদেন করতে হবে। তাদের নামে কোন
                          অভিযোগ কারো কাছে করা যাবে না।
                        </p>
                      </div>
                      <div>
                        <a
                          href="#2"
                          className="btn btn-sm btn-danger text-white"
                        >
                          Report
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="bg-prime p-3">
                  <h6 className="text-center text-danger">এজেন্ট লিস্টঃ</h6>
                  <div className="bg-sec text-white p-2 border-radius mt-3">
                    <p>
                      একাউন্ট খুলতে নিম্বের অনলাইন এজেন্ট লিস্ট এ ক্লিক করুন।
                      এজেন্ট লিষ্ট এর এজেন্ট দের সাথে ইউজার দের শুধু মাত্র
                      হোয়াটসাপ এর মাধ্যমে যোগাযোগ করতে হবে। হোয়াটসাপ ছাড়া
                      অন্য কোন মাধ্যমে যোগাযোগ করলে বা লেনদেন করলে তা গ্রহনযোগ্য
                      হবে না। হোয়াটসাপ এ যোগাযোগ করতে হলে এজেন্ট লিস্টে
                      হোয়াটসাপ আইকন উপরে ক্লিক করুন অথবা ফোন নাম্বার টি মোবাইলে
                      সেভ করে তাকে হোয়াটসাপ এ মসেজ পাঠাতে পারবেন। হোয়াটসাপ এপ
                      টি আপনার মোবাইলে আগে থেকেই থাকতে হবে। না থাকলে গুগুল প্লে
                      থেকে ইন্সটল করে নিন।
                    </p>
                  </div>
                </div>
              </div>
            </section>
            <section className="instruction-grid mb-4">
              <div className="d-flex gap-3">
                <div className="bg-prime p-3">
                  <h6 className="text-center text-danger">
                    কিভাবে একাউন্ট খুলবেনঃ
                  </h6>
                  <div className="bg-sec text-white p-2 border-radius">
                    <p>
                      {VELKI_APP_NAME}- এ একাউন্ট করতে হলে আপনার এজেন্ট এর
                      মাধ্যমে একাউন্ট খুলতে হবে। এজেন্ট এর মাধ্যমেই টাকা ডিপোজিট
                      এবং উইথড্র করতে হবে। আপনি যে এজেন্ট এর কাছ থেকে একাউন্ট
                      খুলবেন তার সাথেই সব সময় লেনদেন করতে হবে। ঠিক কোন এজেন্ট
                      কে টাকা দিবেন এবং কিভাবে তার সাথে লেনদেন করবেন তার বুঝতে
                      হলে আপনার নিম্বের তথ্য গুলো পড়া জরুরী।
                    </p>
                  </div>
                </div>
                <div className="bg-prime p-3">
                  <h6 className="text-center text-danger">
                    {VELKI_APP_NAME}এর নতুন সব আপডেট
                  </h6>
                  <div className="bg-sec text-white p-2 border-radius">
                    <p>...</p>
                  </div>
                </div>
              </div>
            </section>
          </main>
          <Modal />
        </div>

        {/* <div id="wrapper">
          <span>
            <Link to="/">
              {
                
                <SpanImage
                  url="/images/velki-agentlist-top.jpg"
                  width="100%"
                  height="auto"
                />
              }             
            </Link>
          </span>
          <Navigation />
          <div className="dashboard-contents">
            <div id="licenseinfo">
              <div className="header">
                <div className="title">কুইক মাস্টার এজেন্ট নম্বর:</div>
                <div className="legend"></div>
              </div>
              <div className="body">
                <div className="code">
                  <div
                    className="table-responsive"
                    style={{ minHeight: "50px", width: "95%" }}
                  >
                    <table className="data" style={{ width: "95%" }}>
                      <thead>
                        <Agent
                          type="Type"
                          name="Name"
                          id="Id No"
                          app="App Link"
                          phone="Phone Number"
                          uplink="Complain"
                        />
                      </thead>
                      <tbody>
                        {agents.slice(0, 1).map((item) => (
                          <AgentBody
                            key={item.id}
                            keyid={item.id}
                            rowcls="odd"
                            type={(() => {
                              switch (item.agent_type) {
                                case "site_admin":
                                  return "Site Admin";
                                case "customer_service":
                                  return "Customer Service";
                                case "super_admin":
                                  return "Super";
                                case "super_sub":
                                  return "Super Sub";
                                case "master_admin":
                                  return "Master";
                                default:
                                  return "Customer";
                              }
                            })()}
                            name={item.agent_name}
                            id={item.id}
                            fb={item.agent_fb}
                            app={item.agent_phone}
                            phone={item.agent_phone}
                            uplink={item.agent_upline}
                          />
                        ))}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>

          {datarules.map((item, index) => (
            <HomeElement
              key={index}
              title={item.title}
              head={item.head}
              desc={item.desc}
            />
          ))}
        </div> */}
      </>
    );
  }
}
