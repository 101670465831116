import React, { Component } from "react";

class AgentDetailsModal extends Component {
  render() {
    return (
      <div className="modal-body row">
        <div className="col-md-6">
          <div className="d-flex flex-column">
            <p className="form-label">Name</p>
            <p className="form-control text-white detail-name2">
              {this.props.name}
            </p>
          </div>
        </div>
        <div className="col-md-6">
          <div className="d-flex flex-column">
            <p className="form-label">Type</p>
            <p className="form-control text-white detail-name2">
              {this.props.type}
            </p>
          </div>
        </div>
        <div className="col-md-6">
          <div className="d-flex flex-column">
            <p className="form-label">Number</p>
            <p className="form-control text-white detail-name2">
              {this.props.phone}
            </p>
          </div>
        </div>
        <div className="col-md-6">
          <div className="d-flex flex-column">
            <p className="form-label">ID Number</p>
            <p className="form-control text-white detail-name2">
              {this.props.id}
            </p>
          </div>
        </div>

        <div className="col-md-6">
          <div className="d-flex flex-column">
            <p className="form-label">Message</p>
            <div className="d-flex flex-row">
              {this.props.phone && (
                <a
                  className="btn btn-sm bg-success"
                  href={`https://wa.me/${this.props.phone}`}
                  target="_blank"
                  rel="noreferrer"
                >
                  <i className="fa fa-whatsapp" aria-hidden="true"></i> Whatsapp
                </a>
              )}
            </div>
          </div>
        </div>
        <div className="col-md-6">
          <div className="d-flex flex-column">
            <p className="form-label">Message</p>
            <div className="d-flex flex-row">
              {this.props.facebook && (
                <a
                  className="btn btn-sm bg-info"
                  href={this.props.facebook}
                  target="_blank"
                  rel="noreferrer"
                >
                  <i className="fa fa-facebook" aria-hidden="true"></i> Facebook
                </a>
              )}
            </div>
          </div>
        </div>

        <div className="col-md-10 mt-2">
          <div className="d-flex flex-row detail-subs">
            {this.props.admin && (
              <button className="btn btn-sm py-0 text-dark bg-warning">
                <span>Admin : {this.props.admin}</span>
              </button>
            )}
            {this.props.subadmin && (
              <button className="btn btn-sm py-0 text-dark bg-warning">
                <span>Sub Admin : {this.props.subadmin}</span>
              </button>
            )}
            {this.props.super && (
              <button className="btn btn-sm py-0 text-dark bg-warning">
                <span>Super : {this.props.super}</span>
              </button>
            )}
          </div>
        </div>
        <div className="col-md-2 mt-2">
          <button className="btn btn-sm py-0 bg-danger">
            <a
              href="https://wa.me/${this.props.phone}"
              target="_blank"
              rel="noreferrer"
            >
              Report
            </a>
          </button>
        </div>
      </div>
    );
  }
}

export default AgentDetailsModal;
