import React from "react";
import ReactDOM from "react-dom/client";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import reportWebVitals from "./reportWebVitals";
import Error from "./Error";
import "./index.css";

import Home from "./pages/Home";
import Admin from "./pages/Admin";
import Subadmin from "./pages/Subadmin";
import Master from "./pages/Master";
import Supersub from "./pages/Supersub";
import Services from "./pages/Services";
import Superadmin from "./pages/Superadmin";
import Complain from "./pages/Complain";
import Search from "./pages/Search";
import Details from "./pages/Details";
import Old from "./pages/Old";
import Affiliate from "./pages/Affiliate";
import Rules from "./pages/Rules";

const router = createBrowserRouter([
  {
    path: "/",
    element: <Home />,
    errorElement: <Error />,
  },

  {
    path: "/admin",
    element: <Admin />,
    errorElement: <Error />,
  },
  {
    path: "/subadmin",
    element: <Subadmin />,
    errorElement: <Error />,
  },
  {
    path: "/superadmin",
    element: <Superadmin />,
    errorElement: <Error />,
  },
  {
    path: "/supersub",
    element: <Supersub />,
    errorElement: <Error />,
  },
  {
    path: "/master",
    element: <Master />,
    errorElement: <Error />,
  },
  {
    path: "/services",
    element: <Services />,
    errorElement: <Error />,
  },

  {
    path: "/complain",
    element: <Complain />,   
    errorElement: <Error />,
    children:[{path:":agentType",element:<Complain/>,children:[{path:":agentId",element:<Complain/>},]},],
  },
  {
    path: "/search",
    element: <Search />,   
    errorElement: <Error />,
    children:[{path:":type",element:<Search/>,children:[{path:":agentId",element:<Search/>},]},],
  },
  {
    path: "/old",
    element: <Old />,
    errorElement: <Error />,
  },
  {
    path: "/details/:agentId",
    element: <Details />,
    errorElement: <Error />,
  },
  {
    path: "/affiliate",
    element: <Affiliate />,
    errorElement: <Error />,
  },{
    path: "/rules",
    element: <Rules />,
    errorElement: <Error />,
  },
]);

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <RouterProvider router={router} />
  </React.StrictMode>
);

reportWebVitals();
