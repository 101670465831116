import React, { Component } from "react";
import VELKI_APP_NAME, { VELKI_API_URL, VELKI_API_KEY, VELKI_HOME_URL  } from "../config/Constant";
export default function Marquee() {
  return (
    <div className="marque-div">
    <marquee  direction="left" className="text-danger">
      সাইটের RULES না পড়ে কেউ এজেন্ট হবেন না,রুলসের বাইরে কোন কাজ
      করলে সেটার দায়ভার আপনাকে বহন করতে হবেই,তখন অজুহাত দেখিয়ে কোন
      লাভ হবে না। লিস্টের বাইরে লেনদেন করে ধরা খেলে, {VELKI_APP_NAME} দায়ি
      নয়
    </marquee>
  </div>
  )
}
