import React, { Component } from "react";
import Whatsapp from "./Whatsapp";
import Facebook from "./Facebook";
import PhoneCall from "./PhoneCall";
import ComplainLink from "./ComplainLink";

class Agent extends Component {
  render() {
    return (
      <>
        <tr id={this.props.keyid} className={this.props.rowcls}>
          <th align="center">{this.props.type}</th>
          <th align="center">{this.props.name}</th>
          <th align="center">{this.props.id}</th>
          <th align="center">{this.props.app}</th>
          <th align="center">{this.props.phone}</th>
          <th align="center">{this.props.uplink}</th>
        </tr>
      </>
    );
  }
}

class AgentBody extends Component {

  render() {
    return (
      <>
        <tr id={this.props.keyid}>
          <td>
            <div className="d-flex justify-content-center align-items-center flex-column">
              <i className="fa fa-user text-danger fs-1 fa-sm" />
              <span className="d-none d-md-block">
                {this.props.name}{" "}
                <i
                  className="fa fa-eye"
                  aria-hidden="true"
                  data-bs-toggle="modal"
                  data-bs-target="#viewAgentModal"
                ></i>
              </span>
            
<small className="badge badge-sm text-bg-success mt-0">
  {(() => {
    switch (this.props.type.toUpperCase()) {
      case "SITE_ADMIN":
        return "ADMIN";
      case "SUB_ADMIN":
        return "SUB ADMIN";
      case "CUSTOMER_SERVICE":
        return "CUSTOMER SERVICE";
      case "SUPER_ADMIN":
        return "SUPER";
      case "MASTER_ADMIN":
        return "MASTER";
      case "AFFILIATE":
        return "AFFILIATE";
      default:
        return "";
    }
  })()}
</small>
            </div>
          </td>
          <td>
            <div
              className="d-flex mx-auto flex-column justify-content-center py-1 px-2 fw-medium align-items-center bg-white border-radius text-black"
              style={{ maxWidth: "max-content" }}
            >
              <span>ID</span>
              <span>{this.props.id}</span>
            </div>
          </td>
          <td>
            <a href={`https://wa.me/${this.props.phone}`}>
              <i className="fa-brands fa-whatsapp text-success fs-1" />
            </a>
          </td>
          <td>
            <div className="d-flex justify-content-center align-items-center flex-column">
              <span className="d-none d-md-block">{this.props.phone}</span>
              <small className="d-block d-md-none" style={{ fontSize: "15px" }}>
                {this.props.phone}
              </small>
              <a href={`https://wa.me/${this.props.phone}`} target="_blank" rel="noreferrer">
              <small className="badge badge-sm text-bg-success mt-2">
                Whatsapp
              </small>
              </a>
            </div>
          </td>
          <td>
            <button
              style={{
                backgroundColor: "red",
                color: "#fff",
                fontSize: "16px",
              }}
              className="btn btn-sm py-0 reportSubAdmin"
              data-id={`https://wa.me/${this.props.uplink}`}
              data-admin={this.props.id}
            >
              <ComplainLink url={this.props.uplink} ctype={this.props.type} />
            </button>
          </td>
        </tr>
      </>
    );
  }
}
class AffiliateBody extends Component {

  render() {
    return (
      <>
        <tr id={this.props.keyid}>
          <td>
            <div className="d-flex justify-content-center align-items-center flex-column">
              <i className="fa fa-user text-danger fs-1 fa-sm" />
              <span className="d-none d-md-block">
                {this.props.name}{" "}
                <i
                  className="fa fa-eye"
                  aria-hidden="true"
                  data-bs-toggle="modal"
                  data-bs-target="#viewAgentModal"
                ></i>
              </span>
            
<small className="badge badge-sm text-bg-success mt-0">
  {(() => {
    switch (this.props.type.toUpperCase()) {
      case "SITE_ADMIN":
        return "ADMIN";
      case "SUB_ADMIN":
        return "SUB ADMIN";
      case "CUSTOMER_SERVICE":
        return "CUSTOMER SERVICE";
      case "SUPER_ADMIN":
        return "SUPER";
      case "MASTER_ADMIN":
        return "MASTER";
      case "AFFILIATE":
        return "AFFILIATE";
      default:
        return "";
    }
  })()}
</small>
            </div>
          </td>
          <td>
            <div
              className="d-flex mx-auto flex-column justify-content-center py-1 px-2 fw-medium align-items-center bg-white border-radius text-black"
              style={{ maxWidth: "max-content" }}
            >
              <span>ID</span>
              <span>{this.props.id}</span>
            </div>
          </td>
          <td>
            <a href={`https://wa.me/${this.props.phone}`}>
              <i className="fa-brands fa-whatsapp text-success fs-1" />
            </a>
          </td>
          <td>
            <div className="d-flex justify-content-center align-items-center flex-column">
              <span className="d-none d-md-block">{this.props.phone}</span>
              <small className="d-block d-md-none" style={{ fontSize: "15px" }}>
                {this.props.phone}
              </small>
              <a href={`https://wa.me/${this.props.phone}`} target="_blank" rel="noreferrer">
              <small className="badge badge-sm text-bg-success mt-2">
                Whatsapp
              </small>
              </a>
            </div>
          </td>
          <td>
            <button
              style={{
                backgroundColor: "red",
                color: "#fff",
                fontSize: "16px",
              }}
              className="btn btn-sm py-0 reportSubAdmin"
              data-id={`https://wa.me/${this.props.uplink}`}
              data-admin={this.props.id}
            >
             AFF ID : {this.props.uplink}
            </button>
          </td>
        </tr>
      </>
    );
  }
}
export default Agent;
export { AgentBody, AffiliateBody };


